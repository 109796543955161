import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  @Input() title: string;
  @Input() content: string;
  @Input() important_warning: string = undefined;
  @Input() buttonText: string = "Confirm";

  @Output() onConfirm = new EventEmitter<string>();

  constructor( public activeModal: NgbActiveModal ) { }

  ngOnInit(): void {}

  onConfirmClick() {
    this.onConfirm.emit("confirmed");
    this.activeModal.close('Confirm click')
  }
}
