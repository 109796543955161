<div [ngClass]="{ 'card-hover': hover }" class="card" style="box-shadow: 2px 2px rgba(128, 128, 128, 0.24); border:1px solid gray">
  <div *ngIf="tabs != undefined" class="card-tab-group">
    <div
      *ngFor="let tab of tabs; let idx = index"
      class="col card-tab"
      (click)="onClickedTab(tab)"
      [ngClass]="{ 'card-tab-selected': tab == selectedTab }"
    >
      {{ tab }}
    </div>
  </div>
  <div class="body" [ngClass]="{'no-hori-padding': noHoriPadding}">
    <h3 *ngIf="heading">{{ heading }}</h3>
    <p *ngIf="text">{{ text }}</p>
    <ng-content></ng-content>
  </div>
</div>
