import { Component, OnInit, Input, HostBinding, Output, EventEmitter, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"]
})
export class CardComponent implements OnInit {
  @Input() class: string;
  @Input() heading: string;
  @Input() text: string;
  @Input() size: string;
  @Input() hover: boolean;
  @Input() tabs: string[];
  @Input() selectedTab: string;
  @Input() noHoriPadding: boolean = false;
  @Output() onTabClick = new EventEmitter<string>();

  @HostBinding("class") classAtrribute: string = `col-12`;

  constructor() {}

  ngOnInit() {
    this.classAtrribute = this.setClass();
    if (this.tabs != undefined && this.selectedTab == undefined) {
      this.selectedTab = this.tabs[0];
    }
  }
  
  private setClass(): string {
    if (this.size === "small") {
      return "col-md-6 col-sm-12";
    } else if (this.size === "medium") {
      return "col-lg-12 col-xl-8";
    } else if (this.size === "large") {
      return "col-12";
    }
  }

  onClickedTab(tab: string) {
    this.selectedTab = tab;
    this.onTabClick.emit(tab);
  }
}
