<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="body">
  <p class="confirm-content">{{ content }}</p>
  <ng-container *ngIf="important_warning != undefined">
    <p class="confirm-content"><b>{{ important_warning }}</b></p>
  </ng-container>
</div>
<div class="modal-footer">
  <button class="button-dark" type="submit" (click)="onConfirmClick()">{{ buttonText }}</button>
  <button class="button-light" style="width: 100px;" type="button" (click)="activeModal.close('Close click')">Cancel</button>
</div>
