<div class="container">
  <div class="row">
    <div class="col-12 offset-0 offset-md-3 col-md-6">
      <app-card size="large">
        <div class="header-login">
          <div class="TreeTracker-login mb-3 tre-title">TreeTracker login</div>
        </div>
        <div class="body-login">
          <form class="form-auth-small">
            <div class="form-group">
              <input type="email"
                     [(ngModel)]="email"
                     class="col-12 Invulveld form-input"
                     id="signin-email"
                     name="email"
                     placeholder="Email"
                     ngModel />
            </div>
            <div class="form-group">
              <input type="password"
                     [(ngModel)]="password"
                     class="col-12 Invulveld form-input"
                     id="signin-password"
                     name="password"
                     placeholder="Password"
                     ngModel />
            </div>
            <div
                 class="d-flex justify-content-between align-items-center form-group mb-0">
              <a hidden
                 class="col-6 forgot-password"
                 href="">Forgot password?</a>
              <button type="submit"
                      class="tre-button"
                      (click)="tryLogin()">
                Login
              </button>
            </div>
          </form>
        </div>
      </app-card>
    </div>
  </div>
</div>
<div class="treetracker-ai-link copyright">
  ©
  <a style="color: inherit; text-decoration: underline;"
     href="https://www.treetracker.ai/">TreeTracker.ai</a>
</div>
<img class="treeTracker-logo logo" src="assets/images/treetracker-logo.svg">