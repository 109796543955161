import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card/card.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from './confirm/confirm.component';

@NgModule({
  declarations: [CardComponent, ConfirmComponent],
  imports: [CommonModule, NgbModule],
  exports: [CardComponent],
})
export class SharedComponentsModule {}
