import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase'

@Injectable()
export class UserService {

  public idToken: string;

  constructor(
   public db: AngularFirestore,
   public afAuth: AngularFireAuth
 ){}

  getCurrentUser() {
    return new Promise<any>((resolve, reject) => {
      var user = firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          resolve(user);
        } else {
          reject('No user logged in');
        }
      })
    })
  }

  // updateCurrentUser(value){
  //   return new Promise<any>((resolve, reject) => {
  //     var user = firebase.auth().currentUser;
  //     user.updateProfile({
  //       displayName: value.name,
  //       photoURL: user.photoURL
  //     }).then(res => {
  //       resolve(res);
  //     }, err => {
  //       console.log("Failed to update current user", err)
  //       reject(err);
  //     })
  //   })
  // }
}
